import React from "react";
import { useEffect, useState } from "react";
import { getAllTickets } from "../../utils/api/tickets";
import Articles from "./Articles";

const AllTickets = () => {
  const [ticketId, setTicketId] = useState(false);
  const [allTickets, setAllTickets] = useState([]);

  const getTicketDetails = (id) => {
    setTicketId(id);
  };

  useEffect(() => {
    handleGetTickets();
  }, []);

  const handleGetTickets = async () => {
    const response = await getAllTickets();
    setAllTickets(response);
  };
  const ticketStatus = {
    1: "New",
    2: "Open",
    3: "Pending Reminder",
    4: "Closed",
    5: "Merged",
    6: "Removed",
    7: "Pending Close",
  };
  return (
    <>
      {ticketId && <Articles ticketId={ticketId} />}
      <div className="absolute bottom-20 right-28  border border-gray2 bg-white shadow-lg">
        <table className=" text-xs block table-fixed divide-y divide-black">
          <thead className=" font-semibold text-gray-700 uppercase ">
            <tr>
              <th scope="col" className="w-32 px-4  text-center py-3">
                Ticket ID
              </th>
              <th scope="col" className="w-48 px-2 text-center py-3">
                Ticket Title
              </th>
              <th scope="col" className="w-32 px-4 text-center py-3">
                Created At
              </th>
              <th scope="col" className="w-32 px-4 text-center py-3">
                Status
              </th>
            </tr>
          </thead>
          <tbody className="text-gray4 block overflow-y-scroll h-80 hide-scroller ">
            {allTickets
              ?.map((ticket) => (
                <tr
                  onClick={() => getTicketDetails(ticket.id)}
                  className={`${ticketId !== ticket.id && "hover:bg-gray3"} cursor-pointer ${
                    ticketId === ticket.id && "active"
                  }`}
                >
                  <td className="w-32 px-4 text-center py-2">
                    #{ticket.number}
                  </td>
                  <td className="w-48 px-2 text-center py-2 ">
                    {ticket.title}
                  </td>
                  <td className="w-32 px-4 text-center py-2">
                    {ticket.created_at?.slice(0, 10)}
                  </td>
                  <td className="w-32 px-4 text-center py-2">
                    {" "}
                    {ticketStatus[ticket.state_id]}
                  </td>
                </tr>
              ))
              .reverse()}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AllTickets;
