import React, { useEffect, useState } from "react";
import { addComment, getArticles } from "../../utils/api/tickets";
import { useForm } from "react-hook-form";
import moment from "moment";
import submitIcon from "../../assets/images/submitIcon.svg";

const Articles = (props) => {
  const { ticketId } = props;
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    if (ticketId !== undefined) {
      handleGetArtciles(ticketId);
    }
  }, [ticketId]);

  const handleGetArtciles = async (ticketId) => {
    const response = await getArticles(ticketId);
    setArticles(response);
  };
  const { register, handleSubmit, reset } = useForm();

  const submitComment = async (data) => {
    data.article.subject = "Comment from user";
    data.article.content_type = "text/html";
    data.article.internal = "false";
    data.all = "true";
    const response = await addComment(data, ticketId);
    if (response.ok) {
      handleGetArtciles(ticketId);
      reset();
    }
    console.log(data);
  };
  return (
    <>
      <div className="bg-white w-96 border border-gray2 summary absolute shadow-lg">
        <div className="divide-y divide-black">
          <p className=" text-sm py-2.5 px-2 text-center">Submit Result</p>
          <div className="block overflow-y-scroll h-71 hide-scroller">
            {articles &&
              articles.map((article) => (
                <div>
                  <p
                    className={`py-2 px-4 text-xs text-gray4 ${
                      article.sender === "Customer" ? "text-right" : "text-left"
                    }`}
                  >
                    {article?.body}
                  </p>
                  <div
                    className={`flex items-center ${
                      article.sender === "Customer"
                        ? "justify-end pr-4 "
                        : "justify-start"
                    }`}
                  >
                    <p
                      className={`text-xxs pl-4 pr-1 py-1 text-gray4 ${
                        article.sender === "Customer"
                          ? "text-right"
                          : "text-left"
                      }`}
                    >
                      {moment(article?.created_at).format("lll")}
                    </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      fill="currentColor"
                      className="bi bi-clock"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                    </svg>
                  </div>
                  <div
                    className={`flex items-center ${
                      article.sender === "Customer"
                        ? "justify-end pr-4"
                        : "justify-start pl-4"
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      fill="currentColor"
                      className="bi bi-person-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                      <path
                        fill-rule="evenodd"
                        d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                      />
                    </svg>
                    <p className="text-sm text-gray4 pl-1">{article?.from}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <hr className="text-slate-500 mt-4"/>
        <form onSubmit={handleSubmit(submitComment)}>
          <textarea
            type="text"
            className=" pl-4 w-82 focus:outline-none relative hide-scroller"
            placeholder="Add comments..."
            {...register("article.body", { required: true })}
          />
          <button type="submit" className="absolute bottom-5 right-2 z-50">
            <img src={submitIcon} alt="submit-icon" />
          </button>
        </form>
      </div>
    </>
  );
};

export default Articles;
