import React from "react";
import { useForm } from "react-hook-form";
import { createTicket } from "../../utils/api/tickets";
import recordingClipIcon from "../../assets/images/recordingClipIcon.svg";
import captureClipIcon from "../../assets/images/captureClipIcon.svg";

const CreateTicket = (props) => {
  const { attachmentData, setAttachmentData, setView } =
    props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  let attachment;
  if (attachmentData) {
    const attachedDataBase = attachmentData.attachmentBase.split(",");
    let name;
    if (attachmentData.type === "video") {
      name = attachmentData.filename + ".webm";
    } else {
      name = attachmentData.filename + ".png";
    }
    attachment = [
      {
        filename: name,
        data: attachedDataBase[1],
        "mime-type":
          attachmentData.type === "video" ? "video/webm" : "image/png",
      },
    ];
  }
  const handleCreateTicket = async (ticket) => {
    ticket.article.type = "note";
    ticket.article.content_type = "text/html";
    ticket.article.internal = false;
    ticket.article.time_unit = "25";
    ticket.group = "Users";
    ticket.group_id = 1;
    if (attachmentData) {
      ticket.article.attachments = attachment;
    }
    const response = await createTicket(ticket);
    if (response.ok) {
      setView("Tickets");
      setAttachmentData();
    }
  };
  return (
    <>
      <div className="bg-white w-100 border border-gray2 bottom-21 right-28  absolute shadow-lg">
        <div className=" overflow-y-scroll h-82 hide-scroller">
          <form onSubmit={handleSubmit(handleCreateTicket)}>
            <div className="mb-2 px-4 py-2">
              <label className="block text-gray-700 text-sm">Title</label>
              <input
                className="shadow appearance-none border border-gray2 rounded w-full py-1 px-3 text-gray-700 focus:outline-none focus:shadow-outline"
                type="text"
                {...register("title", { required: true })}
              />
              {errors.title && (
                <span className="text-red">This field is required</span>
              )}
            </div>
            {attachmentData && (
              <div className="mb-2 px-4 py-1">
                <p className="block text-gray-700 text-sm mb-2">Attachment</p>
                <p className="text-xs text-slate-400 flex">
                  {" "}
                  <img
                    src={
                      attachmentData.type === "image"
                        ? captureClipIcon
                        : recordingClipIcon
                    }
                    alt="clip-icon"
                    className="mr-1"
                  />
                  {attachmentData.filename}
                  {attachmentData.type === "image" ? ".png" : ".webm"}
                </p>
              </div>
            )}
            <div className="mb-2 px-4 py-2">
              <label className="block text-gray-700 text-sm">Subject</label>
              <input
                className="shadow appearance-none border border-gray2 rounded w-full py-1 px-3 text-gray-700 focus:outline-none focus:shadow-outline"
                type="text"
                {...register("article.subject", { required: true })}
              />
              {errors.title && (
                <span className="text-red">This field is required</span>
              )}
            </div>
            <div className=" px-4 py-2">
              <label className="block text-gray-700 text-sm">
                Your Message
              </label>
              <textarea
                className="shadow appearance-none border border-gray2 rounded w-full py-1 px-3 text-gray-700 focus:outline-none focus:shadow-outline"
                type="text"
                rows={attachmentData ? "2":"4"}
                {...register("article.body")}
              />
              {errors.article && (
                <span className="text-red">This field is required</span>
              )}
            </div>
            <div className="flex justify-end mr-4">
              <button className="bg-purple text-white px-5 py-1.5 rounded-md ">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateTicket;
