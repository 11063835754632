import "./App.css";
import { useState } from "react";
import MainPopup from "./component/MainPopup";
import { stopRecoding } from "./utils/recorder";
import mainLogo from "../src/assets/images/mainLogo.svg";
import recodingIcon from "../src/assets/images/recodingIcon.svg";
import captureIcon from "../src/assets/images/CapturingIcon.svg";

function App() {
  //view=> false, Menu, Tickets, Ticket, Recording, Capture, Text
  const [view, setView] = useState(false);
  const [attachmentData, setAttachmentData] = useState();

  const handleStopRecoding = async () => {
    const response = await stopRecoding();
    setAttachmentData(response);
    setView("RecordingForm");
  };
  return (
    <>
      {view === "Recording" || view === "Capture" ? (
        <button
          className="absolute right-8 bottom-4"
          onClick={() => handleStopRecoding()}
        >
          <img src={view === "Recording" ? recodingIcon : captureIcon} alt="recoding-icon" />
        </button>
      ) : (
        <>
          {view !== false && (
            <MainPopup
              view={view}
              setView={setView}
              setAttachmentData={setAttachmentData}
              attachmentData={attachmentData}
            />
          )}
          <button
            className="rounded-full bg-purple absolute right-8 bottom-4"
            type="button"
            id="dropdownMenuButton1u"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            onClick={() => (view ? setView(false) : setView("Menu"))}
          > 
            <img
              src={mainLogo}
              alt="main-logo"
              className={`${view ? "rotate-90" : ""}`}
            />
          </button>
        </>
      )}
    </>
  );
}

export default App;
