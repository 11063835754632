const queryParams = new URLSearchParams(window.location.search);
const email = queryParams.get("email");
const firstname = queryParams.get("firstname");
const lastname = queryParams.get("lastname");
const organisationName = queryParams.get("org");

export async function getAllTickets() {
  const url = `${process.env.REACT_APP_HOST_URL}/api/v1/tickets`;
  const res = await fetch(url, {
    method: "GET",
    headers: {
      "user-email": email,
      "user-firstname": firstname,
      "user-lastname": lastname,
      "user-type": "Customer",
      "user-org":organisationName
    },
  });
  const data = await res.json();
  return data;
}

export async function getArticles(id) {
  const url = `${process.env.REACT_APP_HOST_URL}/api/v1/ticket_articles/by_ticket/${id}`;
  const res = await fetch(url, {
    method: "GET",
    headers: {
      "user-email": email,
      "user-type": "Customer",
      "user-org":organisationName
    },
  });
  const data = await res.json();
  return data;
}

export async function createTicket(data) {
  const url = `${process.env.REACT_APP_HOST_URL}/api/v1/tickets`;
  const res = await fetch(url, {
    method: "POST",
    headers: {
      "user-email": email,
      "user-firstname": firstname,
      "user-lastname": lastname,
      "user-type": "Customer",
      "user-org":organisationName,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return res;
}

export async function addComment(data, id) {
  const url = `${process.env.REACT_APP_HOST_URL}/api/v1/tickets/${id}`;
  const res = await fetch(url, {
    method: "PUT",
    headers: {
      "user-email": email,
      "user-firstname": firstname,
      "user-lastname": lastname,
      "user-type": "Customer",
      "user-org":organisationName,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return res;
}
