import { blobToBase64 } from "./base64Convertor";

let mediaRecorder;
let videoBase64;
let recordedChunks = [];
export async function startRecoding() {
  try {
    let stream = await recordScreen();
    let mimeType = "video/webm";
    mediaRecorder = createRecorder(stream, mimeType);
    return stream;
  } catch (err) {
    console.log("Unable to acquire screen capture: " + err);
  }
}

export async function stopRecoding() {
  const blob = new Blob(recordedChunks, {
    type: "video/webm",
  });
  videoBase64 = await blobToBase64(blob);
  console.log(videoBase64);
  recordedChunks = [];
  let name = window.prompt("Enter the filename");
  return {
    filename: name,
    attachmentBase: videoBase64,
    type: "video",
  };
}

async function recordScreen() {
  return await navigator.mediaDevices.getDisplayMedia({
    audio: true,
    video: { mediaSource: "screen" },
  });
}

function createRecorder(stream) {
  const mediaRecorder = new MediaRecorder(stream);
  mediaRecorder.ondataavailable = function (e) {
    if (e.data.size > 0) {
      recordedChunks.push(e.data);
    }
  };
  mediaRecorder.start(200);
  return mediaRecorder;
}
