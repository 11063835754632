import React, { useState } from "react";
import { ReactSketchCanvas } from "react-sketch-canvas";
import Line1Img from "../assets/images/Line1.svg";
import Line2Img from "../assets/images/Line2.svg";
import Line3Img from "../assets/images/Line3.svg";
import Line4Img from "../assets/images/Line4.svg";
import PencilImg from "../assets/images/PencilIcon.svg";
import StrokeWidthImg from "../assets/images/Stroke-widthImg.svg";
import DownArrowImg from "../assets/images/DownArrowImg.svg";

const ImageEditor = (props) => {
  const { img, setView, setAttachmentData, attachmentData } = props;

  const [color, setColor] = useState("#000");
  const [brushSize, setBrushSize] = useState(4);
  const [sizeDropdown, setSizeDropdown] = useState(false);
  const [eraseMode, setEraseMode] = useState(true);

  const canvas = React.createRef();
  let editedImageData;

  const handleExportImage = async () => {
    editedImageData = {
      filename: attachmentData.filename,
    };
    const data = await canvas.current.exportImage("png");
    editedImageData.attachmentBase = data;
    editedImageData.type = "image";
    setAttachmentData(editedImageData);
    setView("CaptureForm");
  };

  const toggleEraseMode = () => {
    setEraseMode(!eraseMode);
    canvas.current.eraseMode(eraseMode);
  };
  return (
    <>
      <div className="w-150 h-130 bg-white shadow-2xl px-10 pb-10 pt-4 mx-auto mt-14">
        <div className="flex justify-between mb-2">
          <div className="flex">
            <input
              type="color"
              name=""
              id=""
              className="w-6 border-4 border-blue rounded mr-8 cursor-pointer"
              onChange={(e) => setColor(e.target.value)}
            />
            <div className="relative">
              <div
                className="flex mr-10 cursor-pointer "
                onClick={() => setSizeDropdown(!sizeDropdown)}
              >
                <img src={StrokeWidthImg} alt="" className="mr-2 w-6" />
                <img src={DownArrowImg} alt="" className="w-4" />
              </div>
              {sizeDropdown && (
                <div className="absolute top-8 bg-white w-14 px-1 ">
                  <img
                    src={Line1Img}
                    alt=""
                    className="mb-2 w-12 cursor-pointer"
                    onClick={() => {
                      setBrushSize(4);
                      setSizeDropdown(!sizeDropdown);
                    }}
                  />
                  <img
                    src={Line2Img}
                    alt=""
                    className="mb-2 w-12 cursor-pointer"
                    onClick={() => {
                      setBrushSize(6);
                      setSizeDropdown(!sizeDropdown);
                    }}
                  />
                  <img
                    src={Line3Img}
                    alt=""
                    className="mb-2 w-12 cursor-pointer"
                    onClick={() => {
                      setBrushSize(8);
                      setSizeDropdown(!sizeDropdown);
                    }}
                  />
                  <img
                    src={Line4Img}
                    alt=""
                    className="mb-2 w-12 cursor-pointer"
                    onClick={() => {
                      setBrushSize(10);
                      setSizeDropdown(!sizeDropdown);
                    }}
                  />
                </div>
              )}
            </div>
            {/* <input
              type="number"
              name=""
              id=""
              className="border-2 w-20 mr-8"
              value={brushSize}
              onChange={(e) => setBrushSize(e.target.value)}
            /> */}
            <button onClick={() => toggleEraseMode()} className="mr-8">
              {eraseMode ? (
                <img src={PencilImg} alt="Pencil-Icon"></img>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="#555555"
                  class="bi bi-eraser-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828l6.879-6.879zm.66 11.34L3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293l.16-.16z" />
                </svg>
              )}
            </button>
            <button
              onClick={() => canvas.current.undo()}
              className="bg-gray5 text-black px-4 py-1 rounded-md mr-8"
            >
              Undo
            </button>
            <button
              onClick={() => canvas.current.redo()}
              className="bg-gray5 text-black px-4 py-1 rounded-md mr-8"
            >
              Redo
            </button>
            <button
              onClick={() => canvas.current.clearCanvas()}
              className="bg-gray5 text-black px-4 py-1 rounded-md mr-8"
            >
              Clear
            </button>
          </div>
          <button
            onClick={() => handleExportImage()}
            className="bg-purple text-white px-4 py-1 rounded-md"
          >
            Attach
          </button>
        </div>

        <ReactSketchCanvas
          ref={canvas}
          className="w-150 h-120 border-1 rounded-sm"
          strokeWidth={brushSize}
          strokeColor={color}
          backgroundImage={img}
          exportWithBackgroundImage={true}
          clearCanvas={true}
        />
      </div>
    </>
  );
};

export default ImageEditor;
