import React from "react";
import CreateTicket from "./createTicket/CreateTicket";
import AllTickets from "./tickets/AllTickets";
import { capture } from "../utils/capture";
import { startRecoding } from "../utils/recorder";
import recordIcon from "../assets/images/recordIcon.svg";
import captureIcon from "../assets/images/captureIcon.svg";
import textIcon from "../assets/images/textIcon.svg";
import arrowIcon from "../assets/images/arrowIcon.svg";
import ImageEditor from "./ImageEditor";

const MainPopup = (props) => {
  const { view, setView, setAttachmentData, attachmentData } = props;

  const handleStartRecoding = async () => {
    if (attachmentData === undefined) {
      const response = await startRecoding();
      if (response) setView("Recording");
    } else {
      setView("Text");
    }
  };

  const handleCapture = async () => {
    setView("Capture");
    const response = await capture();
    if (response.attachmentBase) {
      setAttachmentData(response);
      setView("Editor");
    }
  };

  return (
    <>
      {view === "Editor" && (
        <ImageEditor
          img={attachmentData.attachmentBase}
          attachmentData={attachmentData}
          setAttachmentData={setAttachmentData}
          setView={setView}
        />
      )}
      {view === "Tickets" && <AllTickets />}
      {(view === "Text" ||view === "RecordingForm" || view === "CaptureForm") && (
        <CreateTicket
          view={view}
          setView={setView}
          setAttachmentData={setAttachmentData}
          attachmentData={attachmentData}
        />
      )}
      <div className="absolute bottom-100 right-28">
        <ul className=" min-w-max absolute bg-white  text-center text-gray2 text-sm rounded-lg shadow-lg z-50 list-none bg-clip-padding  mt-1 border border-1 border-gray m-0">
          <li
            className={`border-b-2 border-gray2 cursor-pointer ${
              view === "Tickets" && "bg-gray3"
            }`}
            onClick={() => setView("Tickets")}
          >
            <p className="dropdown-item py-3.5 font-normal   text-gray-700 hover:bg-gray3 w-20">
              All <br /> Tickets
            </p>
          </li>
          <li>
            <p
              className="dropdown-item pt-3.5 text-purple font-semibold  block w-20  hover:bg-gray-100"
              href="#"
            >
              Create Ticket
            </p>
            <img src={arrowIcon} alt="two-down-arrows" className="mx-auto pb-2 w-3" />
          </li>
          <li
            onClick={() => handleStartRecoding()}
            className={`${view === "RecordingForm" && "bg-gray3"} cursor-pointer` }
          >
            <p
              className="dropdown-item py-3.5  font-normal block w-20   text-gray-700 hover:bg-gray3"
              href="#"
            >
              <img src={recordIcon} alt="recording-icon" className="mx-auto mb-1" />
              Record
            </p>
          </li>
          <li
            onClick={() => handleCapture()}
            className={`${view === "CaptureForm" && "bg-gray3"} cursor-pointer`}
          >
            <p
              className="dropdown-item py-3.5  font-normal block w-20   text-gray-700 hover:bg-gray3"
              href="#"
            >
              <img src={captureIcon} alt="capture-icon" className="mx-auto mb-1" />
              Capture
            </p>
          </li>
          <li
            onClick={() => setView("Text")}
            className={`${view === "Text" && "bg-gray3"} cursor-pointer`}
          >
            <p
              className="dropdown-item text-sm py-3.5  font-normal block w-20   text-gray-700 hover:bg-gray3"
              href="#"
            >
              <img src={textIcon} alt="text-icon" className="mx-auto mb-1" />
              Text
            </p>
          </li>
        </ul>
      </div>
    </>
  );
};

export default MainPopup;
